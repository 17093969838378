.json-preview {
  flex: 1 1 100%;
  padding: 0 8px 8px 0;
}

.json-preview__inner {
  position: relative;
  height: 100%;
  border-radius: var(--cms-radius-200);
}

.json-preview__inner > pre {
  height: 100%;
  margin: 0;
  padding: 16px;
  font-size: 14px;
  font-family: var(--mono-font);
  line-height: 1.5em;
  white-space: pre-wrap;
  border-radius: inherit;
}
