.micro-format-options {
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.micro-format-options--link {
  display: grid;
  grid-template-columns: 1fr;
}

.micro-format-options--picture {
  flex-direction: column;
  flex-wrap: nowrap;
}

.micro-format-options > .label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.micro-format-options--picture {
  padding: 10px;
  background-color: var(--cms-grey-100);
  border-radius: var(--cms-radius-100);
}

.micro-format-options--picture > .label {
  margin-bottom: 0;
}

.micro-format-options__inner {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.micro-format-options__inner:empty {
  display: none;
}

.micro-format-options__item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 6px;
  background-color: var(--cms-grey-000);
  border-radius: var(--cms-radius-100);
}

.micro-format-options__item-top {
  display: flex;
  align-items: center;
  gap: 6px;
}

.micro-format-options__item-top > .input--select {
  flex: 0 0 auto;
}

.micro-format-options__item-group {
  display: flex;
  gap: 6px;
}

.micro-format-options__item-remove {
  position: absolute;
  top: 10px;
  left: 100%;
  border-radius: 0 var(--cms-radius-100) var(--cms-radius-100) 0;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  transition: opacity 160ms var(--cms-transition-easing);
}

.micro-format-options__item:focus-within .micro-format-options__item-remove,
.micro-format-options__item:hover .micro-format-options__item-remove {
  opacity: 1;
  pointer-events: auto;
  touch-action: auto;
}
