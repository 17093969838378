.table-rows-editor {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.table-rows-editor > .label {
  display: flex;
  align-items: center;
  gap: 6px;
}

.table-rows-editor__inner {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 4px;
  background-color: var(--cms-grey-100);
  border-radius: var(--cms-radius-100);
}

.table-rows-editor__item {
  position: relative;
  padding: 4px;
  background-color: var(--cms-grey-000);
  border-radius: var(--cms-radius-100);
}

.table-rows-editor__item-remove {
  position: absolute;
  top: 10px;
  left: 100%;
  border-radius: 0 var(--cms-radius-100) var(--cms-radius-100) 0;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  transition: opacity 160ms var(--cms-transition-easing);
}

.table-rows-editor__item:focus-within .table-rows-editor__item-remove,
.table-rows-editor__item:hover .table-rows-editor__item-remove {
  opacity: 1;
  pointer-events: auto;
  touch-action: auto;
}
