.tabs {
  position: sticky;
  top: 0;
  width: 100%;
  height: var(--tab-bar-height);
  display: flex;
  padding: 6px;
  z-index: 1;
  background-color: var(--cms-grey-000);
  border-bottom: 2px solid var(--cms-grey-100);
}

.tabs__outer {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  overscroll-behavior: contain;
}

.tabs__outer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.tabs__inner {
  display: flex;
  align-items: center;
}

.tabs__inner:empty {
  padding: 0;
}

.tabs__tab,
.tabs__add-button,
.tabs__control {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cms-grey-500);
  border: 0;
  border-radius: var(--cms-radius-200);
  background-color: var(--cms-grey-100);
  cursor: pointer;
}

.tabs__tab {
  position: relative;
  margin: 6px;
}

.tabs__tab--current {
  color: var(--cms-grey-000);
  background: var(--cms-blue-gradient);
}

.tabs__tab-name {
  height: 100%;
  padding: 2px 28px 2px 12px;
  border: 0;
  color: currentColor;
  background-color: transparent;
  white-space: nowrap;
  cursor: pointer;
  border-radius: var(--cms-radius-200);
}

.tabs__tab-close {
  position: absolute;
  top: 50%;
  right: 6px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  padding: 0;
  color: currentColor;
  border-radius: 50%;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 160ms var(--cms-transition-easing);
  transform: translateY(-50%);
}

.tabs__tab-close:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.tabs__tab--current .tabs__tab-close:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

.tabs__tab-close-icon {
  width: 8px;
  height: 8px;
  fill: currentColor;
}

.tabs__add-button {
  margin-right: auto;
}

.tabs__add-button,
.tabs__control {
  flex: 0 0 36px;
  padding: 2px 0;
}

.tabs__add-icon,
.tabs__control-icon {
  width: 14px;
  height: 14px;
  fill: currentColor;
}

.tabs__controls {
  flex: 1 0 186px;
  display: flex;
  gap: 12px;
  padding: 6px;
}

.tabs__control:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
