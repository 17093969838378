:root {
  --tab-bar-height: 60px;
  --side-bar-width: 380px;

  --content-font: "Open Sans", sans-serif;
  --mono-font: "JetBrains Mono", monospace;

  --cms-grey-000: #ffffff;
  --cms-grey-100: #f5f5f5;
  --cms-grey-300: #d3d3d3;
  --cms-grey-500: #383838;
  --cms-blue-gradient: linear-gradient(
    175deg,
    var(--cms-secondary-color, #4177f6),
    var(--cms-primary-color, #004cc2)
  );
  --cms-red: #f26464;
  --cms-green: #4bde97;
  --cms-yellow: #ffb648;
  --cms-blue: #4177f6;
  --cms-magenta: #be2ec7;
  --cms-cyan: #02c1c8;
  --cms-light-blue: #adc5ea;
  --cms-dark-blue: #004cc2;
  --cms-primary-color: var(--cms-dark-blue);
  --cms-secondary-color: var(--cms-blue);
  --cms-shadow-100: 0 5px 10px rgba(0, 0, 0, 0.12);
  --cms-shadow-300: 0 5px 10px rgba(0, 0, 0, 0.24);
  --cms-radius-100: 3px;
  --cms-radius-200: 10px;
  --cms-radius-300: 15px;
  --cms-radius-400: 20px;
  --cms-transition-easing: cubic-bezier(0.4, 0, 0.2, 1);
}

[data-theme="dark"] {
  --cms-grey-500: #ffffff;
  --cms-grey-300: #f5f5f5;
  --cms-grey-100: #383838;
  --cms-grey-000: #1d1d1d;
  --cms-blue-gradient: linear-gradient(
    175deg,
    var(--cms-secondary-color, #4177f6),
    var(--cms-secondary-color, #004cc2)
  );
  --cms-red: #f26464;
  --cms-green: #4bde97;
  --cms-yellow: #ffb648;
  --cms-blue: #4177f6;
  --cms-magenta: #be2ec7;
  --cms-cyan: #02c1c8;
  --cms-light-blue: #adc5ea;
  --cms-dark-blue: #004cc2;
  --cms-primary-color: var(--cms-blue);
  --cms-secondary-color: var(--cms-light-blue);
  --cms-shadow-100: 0 5px 10px rgba(255, 255, 255, 0.12);
  --cms-shadow-300: 0 5px 10px rgba(255, 255, 255, 0.24);
}

body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: var(--content-font);
  background-color: var(--cms-grey-100);
  overscroll-behavior: none;
  overflow-y: scroll;
  overflow-x: visible;
}

body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body::-webkit-scrollbar-track {
  background-color: var(--cms-grey-100);
}

body::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: var(--cms-grey-300);

  transition: background-color 160ms var(--cms-transition-easing);
}

body:hover::-webkit-scrollbar-thumb {
  background-color: var(--cms-secondary-color);
}

body::-webkit-scrollbar-corner {
  background-color: var(--cms-grey-100);
}

*,
*::before,
*::after {
  font-family: inherit;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

*:focus-visible {
  outline: 2px solid var(--cms-secondary-color);
  outline-offset: 3px;
}

#root {
  min-height: inherit;
}
