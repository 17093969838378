.micro-format-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 14px;
  margin-top: 10px;
}

.micro-format-list__item {
  position: relative;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 6px;
  text-align: center;
  color: var(--cms-grey-500);
  font-weight: 700;
  background-color: var(--cms-grey-100);
  border-radius: var(--cms-radius-200);
  user-select: none;
  cursor: grab;
  z-index: 0;
}

.micro-format-list__item::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: var(--cms-blue-gradient);
  z-index: -1;
  opacity: 0;
}

.micro-format-list__item[aria-pressed="true"] {
  color: var(--cms-grey-000);
  box-shadow: var(--cms-shadow-300);
  cursor: grabbing;
  opacity: 0.8;
  pointer-events: none;
  touch-action: none;
  transition: color 160ms var(--cms-transition-easing),
    background-color 160ms var(--cms-transition-easing),
    opacity 160ms var(--cms-transition-easing),
    box-shadow 160ms var(--cms-transition-easing);
  z-index: 3;
}

.micro-format-list__item[aria-pressed="true"]::before {
  opacity: 1;
  transition: opacity 160ms var(--cms-transition-easing);
}

.micro-format-list__item-icon {
  flex: 0 0 16px;
  width: 16px;
  fill: currentColor;
}

.micro-format-list__item-title {
  color: currentColor;
  font-size: 10px;
  text-transform: uppercase;
}
