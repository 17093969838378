.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 5px 3px;
  cursor: pointer;
  user-select: none;
}

.checkbox > .label {
  margin-bottom: 0;
}

.checkbox__input {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
  cursor: pointer;
}

.checkbox__checkmark {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-color: var(--cms-grey-100);
  border-radius: var(--cms-radius-100);
  transform-origin: center;
  transition: background-color 160ms var(--cms-transition-easing),
    transform 320ms var(--cms-transition-easing);
}

.checkbox:focus-within .checkbox__checkmark {
  outline: 2px solid var(--cms-secondary-color);
  outline-offset: 3px;
}

.checkbox__checkmark::before,
.checkbox__checkmark::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: var(--cms-secondary-color);
  border-radius: var(--cms-radius-100, 3px);
  transform-origin: 0% 0%;
}

.checkbox__checkmark::before {
  top: 9px;
  left: 7px;
  transform: rotate(45deg);
  transition: width 100ms 100ms var(--cms-transition-easing);
}

.checkbox__checkmark::after {
  top: 13px;
  left: 8px;
  transform: rotate(305deg);
  transition: width 100ms var(--cms-transition-easing);
}

.checkbox:hover .checkbox__checkmark::before,
.checkbox__input:checked + .checkbox__checkmark::before {
  width: 5px;
  transition: width 100ms var(--cms-transition-easing);
}

.checkbox:hover .checkbox__checkmark::after,
.checkbox__input:checked + .checkbox__checkmark::after {
  width: 10px;
  transition: width 100ms 100ms var(--cms-transition-easing);
}

.checkbox__input:checked + .checkbox__checkmark,
.checkbox:hover .checkbox__input:checked + .checkbox__checkmark {
  background-color: var(--cms-secondary-color);
}

.checkbox__input:checked + .checkbox__checkmark::before,
.checkbox:hover .checkbox__input:checked + .checkbox__checkmark::before,
.checkbox__input:checked + .checkbox__checkmark::after,
.checkbox:hover .checkbox__input:checked + .checkbox__checkmark::after {
  background-color: var(--cms-grey-000);
}
