.button {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 2px 12px;
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
  border-radius: var(--cms-radius-200);
  cursor: pointer;
}

.button:disabled {
  filter: grayscale(100%) opacity(50%);
  cursor: not-allowed;
}

.button--icon-only {
  padding: 4px 6px;
}

.button--compact {
  height: 22px;
  border-radius: var(--cms-radius-100);
}

.button--compact.button--icon-only {
  padding: 2px 0;
}

.button--primary {
  color: var(--cms-grey-000);
  background: var(--cms-blue-gradient);
}

.button--secondary {
  color: var(--cms-grey-500);
  background-color: var(--cms-grey-000);
}

.button--secondary-alt {
  color: var(--cms-grey-500);
  background-color: var(--cms-grey-100);
}

.button--danger {
  color: var(--cms-grey-000);
  background-color: var(--cms-red);
}

.button > svg {
  flex: 0 0 17px;
  height: 17px;
  fill: currentColor;
}

.button--compact > svg {
  flex: 0 0 12px;
  height: 12px;
}
