.input {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.input--inline {
  flex-direction: row;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
}

.input:focus-within {
  z-index: 1;
}

.input__outer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.input--right-decoration .input__outer {
  flex-direction: row-reverse;
}

.input__decoration {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input__inner {
  flex: 1 1 100%;
  position: relative;
  border-radius: var(--cms-radius-100, 3px);
  z-index: 0;
}

.input__inner::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--cms-grey-100, #f5f5f5);
  border-radius: inherit;
  z-index: 1;
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}

.input__inner::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--cms-grey-300, #d3d3d3);
  transform: scaleX(0);
  transform-origin: left center;
  border-radius: inherit;
  z-index: 2;
  opacity: 0.25;
  pointer-events: none;
  touch-action: none;
  transition: transform 320ms
    var(--cms-transition-easing, cubic-bezier(0.4, 0, 0.2, 1));
}

.input__inner:focus-within::after {
  transform: none;
}

.input__field {
  position: relative;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 15px;
  color: var(--cms-grey-500);
  background-color: transparent;
  border: none;
  border-radius: inherit;
  z-index: 3;
  resize: none;
  transition: transform 320ms
    var(--cms-transition-easing, cubic-bezier(0.4, 0, 0.2, 1));
}

.input--compact .input__field {
  padding: 3px 6px;
  font-size: 14px;
}

textarea.input__field {
  height: 100%;
  overflow: hidden;
}

.input__field:disabled,
.input__field [contenteditable="false"] {
  opacity: 0.5;
  cursor: not-allowed;
}

.input__field [contenteditable="false"] * {
  cursor: not-allowed;
}

.input__error {
  margin-top: 6px;
  color: var(--cms-red, #f26464);
  font-style: italic;
  font-size: 14px;
}
