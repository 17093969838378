.details {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.details__variants {
  display: flex;
  flex-direction: column;
}

.details__variants > .label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details__variants-inner {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.details__variants-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

.details__variants-item > .input {
  flex: 1 1 auto;
}
