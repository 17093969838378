.label {
  margin-bottom: 5px;
  color: var(--cms-grey-500);
  font-size: 12px;
  text-transform: uppercase;
}

.label--important {
  font-size: 13px;
  font-weight: 700;
}

.label--compact {
  font-size: 11px;
}

.label--inline {
  margin-bottom: 0;
}
