.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: opacity 160ms var(--cms-transition-easing);
  animation: popup-appearance 160ms var(--cms-transition-easing);
  z-index: 3;
}

@media (min-width: 750px) {
  .popup {
    padding: 15px;
  }
}

.popup--closing {
  opacity: 0;
}

@keyframes popup-appearance {
  0% {
    opacity: 0;
  }
}

.popup__backdrop {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.popup__inner {
  position: relative;
  min-width: 300px;
  max-width: 1000px;
  min-height: 120px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  color: var(--cms-grey-500);
  background-color: var(--cms-grey-000);
  border-radius: var(--cms-radius-200);
  box-shadow: var(--cms-shadow-100);
  animation: popup-inner-appearance 160ms var(--cms-transition-easing);
}

@keyframes popup-inner-appearance {
  0% {
    opacity: 0.4;
    transform: scale(0.2);
  }
}

.popup__close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  fill: var(--cms-grey-500);
  background-color: var(--cms-grey-000);
  border-radius: var(--cms-radius-100);
  border: 0;
  cursor: pointer;
  user-select: none;
  z-index: 1;
}

.popup__close-icon {
  width: 12px;
  height: 12px;
  fill: var(--grey-000);
  background-color: var(--primary);
}

.popup__title {
  padding-right: 36px;
  font-size: 22px;
  font-weight: 700;
}

.popup__scroller {
  max-height: calc(90vh - 40px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

.popup__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
