.advanced-options {
  position: absolute;
  top: 0;
  left: 0;
  width: 360px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 12px 8px;
  background-color: var(--cms-grey-000);
  border-radius: var(--cms-radius-200);
  user-select: auto;
  z-index: 4;
  box-shadow: var(--cms-shadow-100);
  transform-origin: top left;
  animation: advanced-options-appearance 320ms var(--cms-transition-easing);
}

@keyframes advanced-options-appearance {
  0% {
    opacity: 0;
  }
}

.advanced-options__header {
  display: flex;
  align-items: center;
  gap: 9px;
}

.advanced-options__icon {
  width: 32px;
  height: 32px;
  padding: 5px;
  fill: var(--cms-grey-000);
  background: var(--cms-blue-gradient);
  border-radius: var(--cms-radius-200);
}

.advanced-options__format {
  color: var(--color);
  font-size: 14px;
  font-weight: 700;
  font-family: var(--mono-font);
  white-space: nowrap;
}

.advanced-options__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.advanced-options__form > .label {
  margin-bottom: -8px;
}

.advanced-options__form-group {
  display: flex;
  gap: 10px;
  grid-column: 1 / -1;
}

.advanced-options__form-group--column {
  flex-direction: column;
}

.advanced-options__form-group > [data-id="tag"] {
  flex: 0 0 auto;
}

.advanced-options__form-group > [data-id="selector"] {
  flex: 1 1 100%;
}

.advanced-options__form > [data-id="markup"] {
  grid-column: 1 / -1;
}
