.format-tree {
  flex: 1 1 100%;
  padding: 0 0 8px 8px;
}

.format-tree:only-child {
  padding: 0 8px 8px 8px;
}

.format-tree__inner {
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;
  padding: 10px;
  background-color: var(--cms-grey-000);
  border-radius: var(--cms-radius-200);
}

.format-tree__inner::before {
  content: "";
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vw;
  text-align: center;
  color: var(--cms-grey-500);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  background: var(--cms-blue-gradient);
  border-radius: var(--cms-radius-200);
  opacity: 0;
  transition: border-radius 320ms var(--cms-transition-easing),
    opacity 320ms var(--cms-transition-easing),
    inset 320ms var(--cms-transition-easing);
  pointer-events: none;
  touch-action: none;
  z-index: -1;
}

.editor--dragging .format-tree__inner::before {
  inset: -4px;
  opacity: 1;
  border-radius: var(--cms-radius-300);
}

.format-tree__tree {
  position: relative;
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
}

.format-tree__empty-message {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.format-tree__empty-message-icon {
  width: 14px;
  height: 14px;
  margin-bottom: 10px;
  fill: currentColor;
}

.format-tree__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 6px 6px 36px;
  user-select: none;
  transition: opacity 160ms var(--cms-transition-easing);
}

.format-tree__item[aria-pressed="true"] {
  opacity: 0.5;
  pointer-events: none;
  touch-action: none;
  z-index: 3;
}

.format-tree__drag-handle {
  position: absolute;
  top: 18px;
  left: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  outline: none;
}

.format-tree__drag-handle[aria-pressed="true"] {
  cursor: grabbing;
}

.format-tree__drag-handle-icon {
  width: 20px;
  height: 20px;
  fill: var(--cms-grey-300);
  cursor: pointer;
  user-select: none;
  transition: fill 160ms var(--cms-transition-easing);
}

.format-tree__drag-handle:hover .format-tree__drag-handle-icon {
  fill: var(--cms-grey-500);
}

.format-tree__item-name {
  --color: var(--cms-grey-500);
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background-color: var(--cms-grey-100);
  border-radius: var(--cms-radius-200);
}

.editor--dragging .format-tree__item-name {
  cursor: no-drop;
}

.format-tree__item-dropzone-before,
.format-tree__item-dropzone-inside,
.format-tree__item-dropzone-after {
  --bar-height: 8px;
  position: absolute;
  left: calc(0px - var(--bar-height) / 2);
  width: calc(100% + var(--bar-height));
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  cursor: copy;
  transition: opacity 160ms var(--cms-transition-easing);
}

.editor--dragging
  .format-tree__item:not([aria-pressed="true"])
  .format-tree__item-dropzone-before,
.editor--dragging
  .format-tree__item:not([aria-pressed="true"])
  .format-tree__item-dropzone-inside,
.editor--dragging
  .format-tree__item:not([aria-pressed="true"])
  .format-tree__item-dropzone-after {
  pointer-events: auto;
  touch-action: auto;
}

.editor--dragging
  .format-tree__item[aria-pressed="true"]
  .format-tree__item-dropzone-before,
.editor--dragging
  .format-tree__item[aria-pressed="true"]
  .format-tree__item-dropzone-inside,
.editor--dragging
  .format-tree__item[aria-pressed="true"]
  .format-tree__item-dropzone-after {
  pointer-events: none;
  touch-action: none;
}

.editor--dragging .format-tree__item-dropzone-before:hover,
.editor--dragging .format-tree__item-dropzone-inside:hover,
.editor--dragging .format-tree__item-dropzone-after:hover {
  opacity: 1;
}

.format-tree__item-dropzone-before,
.format-tree__item-dropzone-after {
  height: var(--bar-height);
  background: var(--cms-blue-gradient);
  border-radius: var(--cms-radius-300);
}

.format-tree__item-dropzone-inside {
  inset: var(--bar-height) 0 var(--bar-height) calc(0px - var(--bar-height));
  height: calc(100% - var(--bar-height) * 2);
  background: var(--cms-blue-gradient);
  border-radius: var(--cms-radius-200);
  z-index: 0;
}

.format-tree__item-dropzone-before {
  top: 0;
}

.format-tree__item-dropzone-after {
  bottom: 0;
}

.format-tree__item-dropzone-before::before,
.format-tree__item-dropzone-inside::before,
.format-tree__item-dropzone-after::before {
  position: absolute;
  left: 6px;
  bottom: calc(100% + 4px);
  padding: 4px;
  color: var(--cms-grey-000);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 9px;
  letter-spacing: 1px;
  background: var(--cms-blue-gradient);
  border-radius: var(--cms-radius-100);
  box-shadow: var(--cms-shadow-100);
  pointer-events: none;
  touch-action: none;
  z-index: 10;
}

.format-tree__item-dropzone-before::before {
  content: "Avant";
}

.format-tree__item-dropzone-inside::before {
  content: "Dedans";
}

.format-tree__item-dropzone-after::before {
  content: "Apres";
  top: calc(100% + 4px);
  bottom: unset;
}

.format-tree__item-dropzone-before::after,
.format-tree__item-dropzone-after::after {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  right: calc(100% - 8px);
  width: 16px;
  height: 16px;
  background-color: var(--cms-secondary-color);
  border-radius: 50%;
}

.format-tree__item-icon-button {
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.editor--dragging .format-tree__item-icon-button {
  pointer-events: none;
  touch-action: none;
}

.format-tree__item-icon {
  position: relative;
  width: inherit;
  height: inherit;
  padding: 5px;
  fill: var(--cms-grey-000);
  background: var(--cms-blue-gradient);
  border-radius: var(--cms-radius-200);
  transition: box-shadow 160ms var(--cms-transition-easing);
  z-index: 1;
}

.format-tree__item-icon-button:hover .format-tree__item-icon {
  box-shadow: 0 0 0 4px var(--cms-grey-000);
}

.format-tree__item-name-right {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1;
}

.editor--dragging
  .format-tree__item:not([aria-pressed="true"])
  .format-tree__item-dropzone-inside:hover
  + .format-tree__item-name-right {
  --color: var(--cms-grey-000);
}

.editor--dragging .format-tree__item-name-right {
  pointer-events: none;
  touch-action: none;
}

.format-tree__item-key {
  color: var(--color);
  font-size: 14px;
  font-family: var(--mono-font);
  font-weight: 700;
  white-space: nowrap;
}

.format-tree__item-key-tag,
.format-tree__item-key-selector {
  position: relative;
  padding: 1px;
  margin: 0 -1px;
  color: var(--color);
  border-radius: var(--cms-radius-100);
  z-index: 1;
  cursor: pointer;
  transition: background-color 160ms var(--cms-transition-easing);
}

.format-tree__item-key-tag:hover,
.format-tree__item-key-selector:hover {
  background-color: var(--cms-grey-000);
  z-index: 0;
}

.format-tree__item--duplicate .format-tree__item-key-selector,
.format-tree__item--duplicate .format-tree__item-key-selector:hover {
  color: var(--cms-grey-000);
  background-color: var(--cms-red);
}

.format-tree__item-kind {
  display: flex;
  align-items: center;
  color: var(--color);
  font-size: 12px;
  font-family: var(--mono-font);
  white-space: nowrap;
}

.format-tree__item-remove {
  position: absolute;
  left: 100%;
  top: var(--cms-radius-200);
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: var(--cms-grey-500);
  background-color: var(--cms-red);
  border: 0;
  border-radius: 0 var(--cms-radius-100) var(--cms-radius-100) 0;
  cursor: pointer;
  opacity: 0;
  transform-origin: left center;
  transform: scale(0.9);
  user-select: none;
  pointer-events: none;
  touch-action: none;
  transition: opacity 160ms var(--cms-transition-easing),
    transform 160ms var(--cms-transition-easing);
}

.editor:not(.editor--dragging)
  .format-tree__item-name:hover
  .format-tree__item-remove {
  opacity: 1;
  transform: none;
  pointer-events: auto;
  touch-action: auto;
}

.format-tree__item-remove-icon {
  width: 12px;
  height: 12px;
  fill: var(--cms-grey-000);
}

.format-tree__markup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  padding-left: 48px;
  font-size: 12px;
  font-family: var(--mono-font);
  color: var(--cms-grey-500);
  cursor: pointer;
}

.format-tree__markup pre {
  max-width: 280px;
  margin: 0 0 0 8px;
  padding: 4px;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: var(--cms-grey-100);
  border-radius: var(--cms-radius-100);
  transition: background-color 160ms var(--cms-transition-easing),
    box-shadow 160ms var(--cms-transition-easing);
  overflow: hidden;
}

.format-tree__markup pre:hover {
  background-color: var(--cms-grey-000);
  box-shadow: 0 0 0 4px var(--cms-grey-100);
}

.format-tree__children {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
