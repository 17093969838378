.editor {
  position: relative;
  flex: 1 1 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  z-index: 0;
}

.editor__side-bar {
  position: relative;
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  border-right: 2px solid var(--cms-grey-100);
  z-index: 1;
}

.editor__side-bar-top {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 2px solid var(--cms-grey-100);
}

.editor__side-bar-top > .label {
  display: flex;
  align-items: center;
  gap: 6px;
}

.editor__side-bar-bottom {
  flex: 1 1 auto;
  padding: 16px;
}

.editor__content {
  position: relative;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--cms-grey-100);
  z-index: 0;
}

.editor__content-buttons {
  display: flex;
  gap: 6px;
  padding: 6px;
}

.editor__content-button--preview {
  margin-left: auto;
}

.editor__content-inner {
  flex: 1 1 100%;
  display: flex;
  align-items: stretch;
  gap: 8px;
}

.editor__content-tree {
  flex: 1 1 100%;
  padding: 0 0 8px 8px;
}

.editor__content-tree-inner {
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;
  padding: 30px 10px;
  background-color: var(--cms-grey-000);
  border-radius: var(--cms-radius-200);
}
