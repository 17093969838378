.theme-switcher {
  height: 36px;
  flex: 0 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  color: var(--cms-grey-500);
  border: 0;
  border-radius: var(--cms-radius-200);
  background-color: var(--cms-grey-100);
  cursor: pointer;
}

.theme-switcher__icon {
  width: 14px;
  height: 14px;
  fill: currentColor;
}
